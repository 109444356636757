:root {
  /* BASE COLORS */
  --color-black: hsl(0, 0%, 0%);
  --color-white: hsl(360, 100%, 100%);
  --color-background: hsl(138, 42%, 86%);

  /* BRAND COLORS */
  --color-brand-1: hsl(130, 45%, 90%);
  --color-brand-2: hsl(166, 99%, 33%);
  --color-brand-3: hsl(165, 41%, 23%);
  --color-brand-4: hsl(169, 44%, 15%);
  --color-brand-5: hsl(171, 59%, 11%);
  --color-brand-6: hsl(182, 73%, 7%);

  /* GRAYS */
  --color-gray-1: hsl(0, 0%, 98%);
  --color-gray-2: hsl(0, 15%, 90%);
  --color-gray-3: hsl(0, 0%, 60%);
  --color-gray-4: hsl(0, 0%, 30%);
  --color-gray-5: hsl(0, 0%, 11%);

  /* MISC */
  --color-link: hsl(195, 100%, 33%);
  --color-sun: hsl(61, 92%, 66%);

  /* FONT FAMILIES */
  --font-mukta: 'Mukta', sans-serif;
  --font-noto-sans: 'Noto Sans', sans-serif;

  /* FONT WEIGHTS */
  --weight-regular: 400;
  --weight-semi-bold: 600;
  --weight-extra-bold: 800;

  /* GLOBAL MEASUREMENTS */
  --gutter: 16px;
  --button-border-focus-radius: 6px;
  --button-border-radius: 4px;

  /* TRANSITION */
  --global-transition: .3s ease;
}