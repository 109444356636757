.about-me {
  width: 100%;
  max-width: 960px;

  @media (min-width: 576px) {
    grid-column-gap: 32px;
  }
  @media (max-width: 799px) {
    margin-top: 1.5em;
    grid-row-gap: 18px;
  }
  @media (min-width: 800px) {
    margin-top: 3em;
    grid-row-gap: 20px;
  }
}

.about-me__skills {
  @media (min-width: 1024px) {
    grid-column-start: 9;
  }
}

.about-me__heading {
  margin-bottom: .5em;
  width: 100%;
  flex-shrink: 0;
  color: var(--color-brand-4);
}

.about-me__heading--skills {
  @media (min-width: 576px) {
    margin-top: .25em;
  }
}

.about-me__copy {
  color: var(--color-brand-4);

  &:last-of-type {
    margin-top: .75em;
  }
}

.about-me__list__item {
  color: var(--color-gray-4);
  
  &:not(:last-of-type) {
    margin-bottom: .5em;
  }
}

.about-me__logos {
  max-width: 700px;
  display: grid;
  align-items: baseline;
  
  @media (max-width: 719px) {
    grid-template-columns: repeat(2, auto);
    grid-gap: 24px;
  }
  @media (min-width: 720px) {
    grid-template-columns: repeat(4, auto);
    grid-gap: 56px;
  }
}

.about-me__logo {
  height: auto;

  @media (min-width: 720px) {
    justify-self: center;
  }
}

.about-me__logo--zipcar,
.about-me__logo--netflix {
  @media (max-width: 719px) {
    max-width: 100px;
  }
  @media (min-width: 720px) {
    max-width: 140px;
  }
}

.about-me__logo--rebud {
  @media (max-width: 719px) {
    max-width: 136px;
  }
  @media (min-width: 720px) {
    max-width: 188px;
  }
}
.about-me__logo--apple {
  @media (max-width: 719px) {
    max-width: 30px;
  }
  @media (min-width: 720px) {
    max-width: 40px;
  }
}