.projects {
  width: 100%;
  max-width: 960px;

  @media (max-width: 799px) {
    margin-top: 3em;
  }
  @media (min-width: 800px) {
    margin-top: 6em;
  }
}

.projects__heading {
  margin-bottom: .5em;
  color: var(--color-brand-4);
}

.projects__link {
  display: inline-flex;
  flex-direction: column;
  align-content: center;
  text-decoration: none;
  
  &:hover,
  &:focus {
    .projects__link__img {
      border-color: var(--color-link);
    }
    .projects__link__name {
      color: var(--color-link)
    }
  }
  
  @media (max-width: 575px) {
    max-width: 356px;
  }
}

.projects__link__img {
  width: 100%;
  height: auto;
  border: 4px solid var(--color-brand-3);
  border-radius: 4px;
}

.projects__link__name {
  padding: 8px 10px;
  color: var(--color-brand-3);
  text-align: center;
}