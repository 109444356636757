.hero {
  margin-right: auto;
  margin-left: auto;
  grid-area: hero;
  display: flex;
  flex-direction: column;
  max-width: 960px;
  width: 100%;
  position: relative;
  border-bottom: 2px solid var(--color-brand-4);

  @media (max-width: 799px) {
    padding: 24px 12px 4px 12px;
  }
  @media (min-width: 800px) and (max-width: 975px) {
    padding: 42px 12px 8px 12px;
  }
  @media (min-width: 976px) {
    padding: 42px 12px 8px 0;
  }
}

.hero__headings {
  display: flex;
  align-items: baseline;

  @media (max-width: 659px) {
    margin-bottom: 1.5em;
    flex-direction: column;
  }
  @media (min-width: 660px) {
    margin-bottom: 3em;
  }
}

.hero__headings__display {
  color: var(--color-brand-4);

  @media (max-width: 659px) {
    margin-bottom: .25em;
  }
}

.hero__headings__sub-display {
  color: var(--color-brand-3);

  @media (min-width: 660px) {
    margin-left: 24px;
  }
}
