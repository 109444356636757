.mountain-svg {
  position: absolute;
  bottom: -6px;
  cursor: pointer;

  @media (max-width: 975px) {
    left: 3px;
  }
  @media (min-width: 976px) {
    left: -5px;
  }
}

.mountain-svg__first,
.mountain-svg__second,
.mountain-svg__third {
  position: relative;

  @media (max-width: 659px) {
    width: 64px;
    height: 64px;
  }
  @media (min-width: 660px) {
    width: 86px;
    height: 86px;
  }
}

.mountain-svg__fourth {
  position: relative;

  @media (max-width: 659px) {
    width: 54px;
    height: 54px;
    bottom: 1px;
    left: -36px;
  }
  @media (min-width: 660px) {
    width: 76px;
    height: 76px;
    left: -46px;
  }
}

.mountain-svg__fith {
  position: relative;

  @media (max-width: 559px) {
    display: none;
  }
  @media (min-width: 560px) {
    width: 66px;
    height: 66px;
    left: -58px;
  }
}

.mountain-svg__first {
  @media (max-width: 559px) {
    left: 0;
  }
  @media (min-width: 560px) {
    left: 0;
  }
}

.mountain-svg__second {
  @media (max-width: 559px) {
    left: -12px;
  }
  @media (min-width: 560px) {
    left: -16px;
  }
}

.mountain-svg__third {
  @media (max-width: 559px) {
    left: -24px;
  }
  @media (min-width: 560px) {
    left: -28px;
  }
}

.mountain-svg__bounce,
.mountain-svg__bounce-2,
.mountain-svg__bounce-3,
.mountain-svg__bounce-4 {
  stroke: var(--color-gray-2);
  stroke-width: 1px;
  position: relative;

  [data-cloud-state="clear"] & {
    animation-name: poof;
    animation-duration: 6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.68, 0.28, 0.49, 0.76);
  }
  [data-cloud-state="cloudy"] & {
    animation-name: bounce;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-timing-function: cubic-bezier(0.68, 0.28, 0.49, 0.76);
  }
}

.mountain-svg__bounce {
  animation-delay: 3s;
}
.mountain-svg__bounce-3 {
  animation-delay: 2s;
}
.mountain-svg__bounce-4 {
  animation-delay: 4s;
}
@keyframes bounce {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(3px);
  }
  60% {
    transform: translateY(-2px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes poof {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  30% {
    transform: translateY(10px);
  }
  60% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(50px);
    opacity: 0;
  }
}

.mountain-svg__flag {
  animation: flag ease 10s;
  animation-iteration-count: 1;
  transform: translateY(0px);
}
@keyframes flag {
  0% {
    transform: translateY(10px);
  }
  25% {
    transform: translateY(10px);
  }
  70% {
    transform: translateY(4px);
  }
  85% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}

.mountain-svg__sun {
  stroke: var(--color-gray-2);

  [data-cloud-state="clear"] & {
    animation-name: sun;
    animation-duration: 6s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
  [data-cloud-state="cloudy"] & {
    animation-name: sunCloud;
    animation-duration: 6s;
    animation-delay: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-timing-function: ease;
  }
}
@keyframes sun {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes sunCloud {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}